<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->

        <ProjectInfo />

        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <SelectButton
          v-model="toogle"
          :options="options"
          aria-labelledby="single"
        />
        <h3
          style="
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
            line-height: 140.62%;
            color: rgba(0, 0, 0, 0.7);
          "
        >
          Selecione que resultado do produto que você deseja relacionar com
          fatores ambientais
        </h3>

        <p
          style="
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            color: #585857;
          "
        >
          Estão listados apenas resultados com diferença significativa de
          probabilidade, entre o produto e o controle. Os valores de R para os fatores ambientais referem-se ao coeficiente de correlação entre a resposta do fator e do resultado do produto selecionado, a partir de uma análise multivariada com base na comunidade microbiana.
        </p>
        <Dropdown
          style="margin-top: 30px"
          v-model="selected"
          :options="Analysis"
          optionLabel="name"
          optionValue="name"
          placeholder="Selecione a opção desejada"
        />

        <div class="grid" style="padding-top: 30px">
          <div
            style="text-align: center"
            class="col-12 xl:col-6 md:col-12 lg:col-12 sm:col-12 .col-12 card"
          >
            <h3
              style="
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 140.62%;
                color: rgba(0, 0, 0, 0.7);
              "
            >
              Os fatores ambientais correlacionados (R) com a microbiota que
              <strong style="color: #22ac00">estimularam</strong> a produtividade foram:
            </h3>
            <div class="flex flex-wrap" style="justify-content: center">
              <div
                v-for="(results, index) in AccordionData.Positives"
                :key="results"
                style="padding-right: 10px"
              >
                <h5>{{ AccordionData.Positives[index].name }}</h5>
                <Badge
                  value="6"
                  style="width: 100px; height: 100px"
                  size="xlarge"
                  :style="{
                    backgroundColor: AccordionData.Positives[index].color,
                    width: AccordionData.Positives[index].width,
                    height: AccordionData.Positives[index].height,
                  }"
                  severity="success"
                  ><p style="padding-top: 30px">
                    {{ AccordionData.Positives[index].result }}
                  </p></Badge
                >
              </div>
            </div>
            <Button
              style="color: white; margin-top: 30px"
              label="Clique para visualizar as análises multivariadas."
              @click="showContent()"
            />
            <div v-if="positiveContent === true">
              <h3
                style="
                  font-style: normal;
                  font-weight: 700;
                  font-size: 15px;
                  line-height: 140.62%;
                  color: rgba(0, 0, 0, 0.7);
                  text-align: start;
                  padding-top: 50px;
                "
              >
                Análise multivariada
              </h3>
              <p style="font-size:11px; color: #585858">Este gráfico mostra a correlação (cores) entre a microbiologia do solo das áreas com produto e os fatores indicados.</p><br>
              <Image src="/images/textura.svg" alt="Image" />
            </div>
          </div>
          <div
            style="text-align: center"
            class="col-12 xl:col-6 md:col-12 lg:col-12 sm:col-12 .col-12 card"
          >
            <h3
              style="
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 140.62%;
                color: rgba(0, 0, 0, 0.7);
              "
            >
              Os fatores ambientais correlacionados (R) com a microbiota - que
              <strong style="color: red">desfavoreceram</strong> o aspecto
              selecionado - foram:
            </h3>
            <div class="flex flex-wrap" style="justify-content: center">
              <div
                v-for="(results, index) in AccordionData.Negatives"
                :key="results"
                style="padding-right: 10px"
              >
                <h5>{{ AccordionData.Negatives[index].name }}</h5>
                <Badge
                  value="6"
                  size="xlarge"
                  :style="{
                    backgroundColor: AccordionData.Negatives[index].color,
                    width: AccordionData.Negatives[index].width,
                    height: AccordionData.Negatives[index].height,
                  }"
                  severity="success"
                  ><p style="padding-top: 30px">
                    {{ AccordionData.Negatives[index].result }}
                  </p></Badge
                >
              </div>
            </div>
            <Button
              style="color: white; margin-top: 30px"
              label="Clique para visualizar as análises multivariadas."
              @click="showNegativeContent()"
            />
            <div v-if="negativeContent === true">
              <h3
                style="
                  font-style: normal;
                  font-weight: 700;
                  font-size: 15px;
                  line-height: 140.62%;
                  color: rgba(0, 0, 0, 0.7);
                  text-align: start;
                  padding-top: 50px;
                "
              >
                Análise multivariada
              </h3>
              <p style="font-size:11px; color: #585858">Este gráfico mostra a correlação (cores) entre a microbiologia do solo das áreas com produto e os fatores indicados.</p><br>
              <Image src="/images/ph.svg" alt="Image" style="object-fit: cover;object-position: 25% 25%; "/>
            </div>
          </div>
        </div>

        <!-- Produtividade ---------------------------------------------------------------------------------------------->
        <div class="chartContainer" style="padding-top: 17px">
          <div class="grid" style="justify-content: center">
            <div
              style="padding-top: 15px"
              class="col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 .col-12 card"
            >
              <Dropdown
                v-model="select_items[0]"
                :options="select_items"
              />
              <Dropdown
                v-model="select_items[1]"
                :options="select_items"
              />
              <div class="grid" style="width: 90%">
                <div
                  style="
                    align-self: center;
                    text-align-last: end;
                    padding-top: 150px;
                  "
                  class="col-2 xl:col-2 md:col-2 lg:col-2 sm:col-2 .col-2"
                >
                  <p class="textRotate" style="font-weight: 500; text-align: center">
                    pH
                  </p>
                </div>
                <div class="col-8 xl:col-8 md:col-8 lg:col-8 sm:col-8 .col-8">
                  <Chart
                    type="line"
                    :data="multiAxisData"
                    :options="multiAxisOptions"
                  />
                </div>
                <div
                  style="align-self: center; padding-top: 150px"
                  class="col-2 xl:col-2 md:col-2 lg:col-2 sm:col-2 .col-2"
                >
                  <p class="textRotate" style="font-weight: 500">
                    Fósforo (mg/dm3)
                  </p>
                </div>
              </div>
              <div
                style="text-align: center; padding-right: 80px"
                class="col-12 xl:col-12 md:col-12 lg:col-12 sm:col-12 .col-12"
              >
                <span style="font-weight: 500">
                  Aumento na produtividade (%)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/EnvironmentalAnalysis/BodyHeader.vue";

export default {
  data() {
    return {
      select_items: [
      "pH",
      "Fósforo",
      "Cobre",
      "Enxofre",
      "Zinco"
      ],

      positiveContent: false,
      negativeContent: false,
      AccordionData: {
        Positives: [
          {
            name: "pH",
            result: "R=0,75",
            color: "#405412",
            width: "95px",
            height: "95px",
          },
          {
            name: "Fósforo",
            result: "R=0,6",
            color: "#405412",
            width: "85px",
            height: "85px",
          },
        ],
        Negatives: [
          {
            name: "Cobre",
            result: "R=0,6",
            color: "#E18A6E",
            width: "85px",
            height: "85px",
          },
          {
            name: "Enxofre",
            result: "R=0,8",
            color: "#6F4F37",
            width: "100px",
            height: "100px",
          },
          {
            name: "Zinco",
            result: "R=0,5",
            color: "#6F4F37",
            width: "80px",
            height: "80px",
          },
        ],
      },
      value1: "R=0,6",
      toogle: "Vegetativo",
      options: ["Vegetativo", "Reprodutivo"],
      lineDisplay: ["pH", "Fósforo"],
      graph: "",
      GraphOptions: ["Baixa p < 0,1", "Média p < 0,05", "Alta p < 0,01"],
      multiAxisOptions: {
        stacked: false,
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            type: "linear",
            display: true,
            position: "left",
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y1: {
            type: "linear",
            display: true,
            position: "right",
            ticks: {
              color: "#495057",
            },
            grid: {
              drawOnChartArea: false,
              color: "#ebedef",
            },
          },
        },
      },
      multiAxisData: {
        labels: ["60", "70", "80", "90", "100", "110", "120"],
        datasets: [
          {
            label: "pH",
            fill: false,
            borderColor: "#7A7A7A",
            yAxisID: "y",
            tension: 0.4,
            data: [5.6, 5.4, 5.8, 6.1, 6.2, 6, 6.6, 6.8, 6.9],
          },
          {
            label: "Fósforo",
            fill: false,
            borderColor: "#90A956",
            yAxisID: "y1",
            tension: 0.4,
            data: [15, 18, 16, 30, 35, 40, 31, 33, 35],
          },
        ],
      },
      selected: "Produtividade",
      Analysis: [
        { name: "Produtividade", code: "PR" },
        { name: "Concentração de fósforo", code: "FoF" },
        { name: "Concentração de nitrogênio", code: "FoN" },
        { name: "Biodiversidade", code: "BIO" },
        { name: "Controle de fungos fitopatogênicos", code: "AF" },
        { name: "Fixação do nitrogênio", code: "FN" },
        { name: "Solubilização do fósforo", code: "SF" },
      ],

      

      chartData: {
        Produtividade: {
          labels: [
            "Produtividade",
            "pH",
            "Textura",
            "Fósforo",
            "Controle de fungos fitopatogênicos",
            "Antibacterianos",
            "Fixação do nitrogênio",
            "Solubilização do fósforo",
            "Oxidação do enxofre",
          ],
          datasets: [
            {
              label: "Área 1",
              backgroundColor: "rgba(179,181,198,0.2)",
              borderColor: "rgba(179,181,198,1)",
              pointBackgroundColor: "rgba(179,181,198,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(179,181,198,1)",
              data: [5, 4, 4, 3, 5, 4, 5, 4, 3],
            },
            {
              label: "Área 2",
              backgroundColor: "rgba(139,203,81,0.3)",
              borderColor: "rgba(90,181,7,1)",
              pointBackgroundColor: "rgba(90,181,7,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(90,181,7,1)",
              data: [4, 4, 5, 5, 3, 4, 4, 4, 2],
            },
            {
              label: "Área 3",
              backgroundColor: "rgba(220, 197, 124,0.4)",
              borderColor: "rgba(252, 92, 0)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [2, 2, 2, 3, 4, 5, 2, 2, 1],
            },
          ],
          legend: {
            position: "bottom",
          },
        },
        Antibacterianos: {
          labels: [
            "Antibacterianos",
            "pH",
            "Textura",
            "Fósforo",
            "Paenibacillus",
            "Streptomyces",
            "Trichoderma",
            "Penicillium",
          ],
          datasets: [
            {
              label: "Área 1",
              backgroundColor: "rgba(179,181,198,0.2)",
              borderColor: "rgba(179,181,198,1)",
              pointBackgroundColor: "rgba(179,181,198,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(179,181,198,1)",
              data: [5, 4, 4, 3, 5, 4, 3, 5],
            },
            {
              label: "Área 2",
              backgroundColor: "rgba(255,99,132,0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [5, 4, 5, 5, 3, 5, 3, 3],
            },
            {
              label: "Área 3",
              backgroundColor: "rgba(255, 197, 124)",
              borderColor: "rgba(252, 92, 0)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [3, 2, 2, 3, 3, 2, 4, 3],
            },
          ],
        },
        "Fixação do nitrogênio": {
          labels: [
            "Fixação do nitrogênio",
            "pH",
            "Textura",
            "Fósforo",
            "Bradyrhizobium",
            "Rhizobium",
          ],
          datasets: [
            {
              label: "Área 1",
              backgroundColor: "rgba(179,181,198,0.2)",
              borderColor: "rgba(179,181,198,1)",
              pointBackgroundColor: "rgba(179,181,198,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(179,181,198,1)",
              data: [4, 4, 4, 3, 3, 4],
            },
            {
              label: "Área 2",
              backgroundColor: "rgba(255,99,132,0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [5, 4, 5, 5, 4, 5],
            },
            {
              label: "Área 3",
              backgroundColor: "rgba(255, 197, 124)",
              borderColor: "rgba(252, 92, 0)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [3, 2, 2, 3, 3, 3],
            },
          ],
        },
        "Solubilização do fósforo": {
          labels: [
            "Solubilização do fósforo",
            "pH",
            "Textura",
            "Fósforo",
            "Paenibacillus",
            "Acetobacter",
            "Glomus",
          ],
          datasets: [
            {
              label: "Área 1",
              backgroundColor: "rgba(179,181,198,0.2)",
              borderColor: "rgba(179,181,198,1)",
              pointBackgroundColor: "rgba(179,181,198,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(179,181,198,1)",
              data: [4, 4, 4, 3, 3, 5, 2],
            },
            {
              label: "Área 2",
              backgroundColor: "rgba(255,99,132,0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [5, 4, 5, 5, 4, 5, 3],
            },
            {
              label: "Área 3",
              backgroundColor: "rgba(255, 197, 124)",
              borderColor: "rgba(252, 92, 0)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [3, 2, 2, 3, 3, 3, 4],
            },
          ],
        },
        "Oxidação do enxofre": {
          labels: [
            "Oxidação do enxofre",
            "pH",
            "Textura",
            "Fósforo",
            "Acidithiobacillus",
            "Aspergillus",
          ],
          datasets: [
            {
              label: "Área 1",
              backgroundColor: "rgba(179,181,198,0.2)",
              borderColor: "rgba(179,181,198,1)",
              pointBackgroundColor: "rgba(179,181,198,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(179,181,198,1)",
              data: [1, 4, 4, 3, 2, 2],
            },
            {
              label: "Área 2",
              backgroundColor: "rgba(255,99,132,0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [1, 4, 5, 5, 1, 1],
            },
            {
              label: "Área 3",
              backgroundColor: "rgba(255, 197, 124)",
              borderColor: "rgba(252, 92, 0)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [3, 2, 2, 3, 1, 3],
            },
          ],
        },
        "Fósforo (foliar)": {
          labels: [
            "Fósforo (foliar)",
            "pH",
            "Textura",
            "Fósforo",
            "Paenibacillus",
            "Acetobacter",
            "Glomus",
            "Verticillium",
          ],
          datasets: [
            {
              label: "Área 1",
              backgroundColor: "rgba(179,181,198,0.2)",
              borderColor: "rgba(179,181,198,1)",
              pointBackgroundColor: "rgba(179,181,198,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(179,181,198,1)",
              data: [4, 4, 4, 3, 3, 5, 2, 3],
            },
            {
              label: "Área 2",
              backgroundColor: "rgba(255,99,132,0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [5, 4, 5, 5, 4, 5, 3, 5],
            },
            {
              label: "Área 3",
              backgroundColor: "rgba(255, 197, 124)",
              borderColor: "rgba(252, 92, 0)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [3, 2, 2, 3, 3, 3, 4, 3],
            },
          ],
        },
        "Concentração de nitrogênio (foliar)": {
          labels: [
            "Concentração de nitrogênio (foliar)",
            "pH",
            "Textura",
            "Fósforo",
            "Bradyrhizobium",
            "Rhizobium",
          ],
          datasets: [
            {
              label: "Área 1",
              backgroundColor: "rgba(179,181,198,0.2)",
              borderColor: "rgba(179,181,198,1)",
              pointBackgroundColor: "rgba(179,181,198,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(179,181,198,1)",
              data: [4, 4, 4, 3, 3, 4],
            },
            {
              label: "Área 2",
              backgroundColor: "rgba(255,99,132,0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [5, 4, 5, 5, 4, 5],
            },
            {
              label: "Área 3",
              backgroundColor: "rgba(255, 197, 124)",
              borderColor: "rgba(252, 92, 0)",
              pointBackgroundColor: "rgba(255,99,132,1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(255,99,132,1)",
              data: [3, 2, 2, 3, 3, 3],
            },
          ],
        },
      },
      chartOption: {
        plugins: {
          legend: {
            position: "bottom",
            align: "center",
          },
        },
      },
    };
  },
  computed: {
    lineData() {
      if (!this.info) return null;
      return {
        labels: Array.from(
          { length: this.info.graficos1.length },
          (_, i) => i + 1
        ),
        datasets: [
          {
            label: "Informações",
            data: this.info.graficos1,
            fill: false,
            backgroundColor: "#d92546",
            borderColor: "#d92546",
            tension: 0.4,
          },
        ],
      };
    },
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {
    showContent() {
      this.positiveContent = !this.positiveContent;
    },

    showNegativeContent() {
      this.negativeContent = !this.negativeContent;
    },
    openModal() {
      this.displayModal = true;
    },
    closeModal() {
      this.displayModal = false;
    },
    checkCategory() {
      let benefits = [
        "Controle de fungos fitopatogênicos",
        "Antibacterianos",
        "Auxinas",
        "Fixação do nitrogênio",
        "Solubilização do fósforo",
        "Oxidação do enxofre",
      ];
      let foliarCorrelation = [
        "Fósforo (foliar)",
        "Concentração de nitrogênio (foliar)",
      ];
      if (benefits.indexOf(this.selected) > -1) {
        return "BS";
      } else if (foliarCorrelation.indexOf(this.selected) > -1) {
        return "CF";
      }
    },
  },
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
  },
};
</script>

<style>
p {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-left: 5px;
  margin-top: 3px;
}
.info {
  font-style: normal;
  font-weight: 600;
  font-size: 16.3333px;
  line-height: 16px;
  color: #ffffff;
}

.p-accordion
  .p-accordion-tab:first-child
  .p-accordion-header
  .p-accordion-header-link {
  background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
  border-radius: 0px 0px 0px 20px;
  color: white;
}

.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header:not(.p-highlight)
  .p-accordion-header-link {
  background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
  border-radius: 0px 0px 0px 20px;
}

.p-accordion .p-accordion-tab:last-child .p-accordion-content {
  background: #ffffff;
  padding-top: 30px;
  margin-top: -15px;
}

.p-accordion
  .p-accordion-tab:first-child
  .p-accordion-header
  .p-accordion-header-link {
  background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
  border-radius: 0px 0px 0px 20px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140.62%;
  color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #90a956;
  background: #90a956;
}

.p-button {
  color: #ffffff;
  background: #90a956;
  border: 1px solid #90a956;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.filter-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #466107;
}

.cardFilter {
  justify-content: center;
  padding-top: 20px;
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 14px;
  padding-top: 25px;
  margin-bottom: 20px;
  margin-top: 15px;
  border-radius: 14px;
}

.header {
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 140.62%;
  color: rgba(0, 0, 0, 0.7);
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 750;
  color: #212529;
  background: #90a956;
  transition: box-shadow 0.15s;
}

.p-datatable .p-column-header-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
  text-align-last: center;
}
.p-selectbutton .p-button.p-highlight {
  background: #90a956;
  border-color: #90a956;
  color: #fff;
}

.textDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #585857;
}

.textRotate {
  display: inline-block;
  transform: rotate(-90deg);
  transform-origin: left top;
  font-weight: 500;
  color: black;
}
</style>
