<template>
  <div class="grid" style="padding-top: 20px">
    <div class="col-12 xl:col-12 sm:col-12 .col-12">
      <h3
        style="
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 140.62%;
          color: rgba(0, 0, 0, 0.7);
        "
      >
        Análises Ambientais
      </h3>
      <h4 class="textDescription">
        Nesta seção exibimos análises de correlações entre a microbiota e os
        dados ambientais obtidos através das análises químicas do solo. Estas
        análises permitem verificar quais características do solo podem estar
        associadas com a produtividade, biodiversidade, benefícios secundários e
        nutrição da planta. Para utilizar esta análise é preciso que seja
        selecionado qual a superfície de resposta deseja avaliar. São listados
        como superfície de resposta a produtividade e biodiversidade. Além
        disso, mostramos todos os benefícios secundários e nutrientes foliares
        listados neste relatório com aumento significativo no tratamento da
        aplicação do Produto em relação ao Controle.
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.textDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #585857;
      text-align: justify;
}
</style>
